<template>
  <v-dialog v-model="alertDialog" persistent width="700" transition="slide-y-transition">
    <v-card class="pa-6" id="new_alert_card">
      <div class="fsize20 font-weight-bold mb-2" id="new_alert_label">New alert</div>
      <v-divider class="mb-4"></v-divider>
      <form action="" @keyup.enter="validate()">
 <v-text-field
              type="text"
              label="Name"
              height="42px"
              v-model.trim="alertName"
              outlined
              autofocus
              autocomplete="off"
              v-alphaNumericWithSpace
              id="alert_name_inp"
              :error-messages="alerterrorMessage"
              @keyup="alerterrorMessage = null"
              :required="false"
              dense
              class="pr-6 my-4  fsize13"
            ></v-text-field>
      <div class="d-flex row pa-0 ma-0">
        <div class="col-5 pa-0 ma-0">
          <v-autocomplete
            v-model="property"
            :items="propertyItems"
            outlined
            dense
            id="property_inp"
            height="42"
            single-line
            label="Select Account"
            class="funds fsize12"
            item-text="key"
            item-value="value"
            :error-messages="propertyErrorMessage"
          ></v-autocomplete>
        </div>
        <div class="col-7 pa-0 ma-0">
          <div class="px-6">
            <div
              class="
                row
                py-2
                px-4
                ma-0
                d-flex
                align-center
                border-a8a8a8
                rounded
              "
              id="basketDiv"
            >
              <customIcon
                class="d-flex"
                :name="'search'"
                :width="'20'"
                :height="'20'"
                :color="$vuetify.theme.dark ? '#ffffff' :'#282828'"
              />
              <v-divider vertical class="mx-2"></v-divider>
              <input
                type="text"
                name=""
                id="new_alert_search" 
                v-model="alertSearch"
                @keyup="commomErrorMessage = null;isSelected = false;ltpValue = null"
                class="border-none outline-none input-width fsize12 text-uppercase"
                :class="$vuetify.theme.dark ? 'white--text' : ''"
                placeholder="Search eg: infy bse, nifty fut"
                ref="basketsearch"
              />
            </div>
            <v-sheet
              transition="fade-transition"
              tile
              class="orderSearchList mt-2"
              id="search_alert_sheet"
              :width="350"
              v-click-outside="onClickOrderOutside"
              v-if="basketSearch.length > 0"
            >
              <ul class="pl-0 alertSearchAlign" :class="$vuetify.theme.dark ? 'card-dark' : ''" id="search_alert_sheet_ul">
                <li :id="`${index}_search_alert_sheet_li`"
                  class="
                    px-2
                    py-3
                    border-bottom-light
                    d-flex
                    align-center
                    cursor
                  "
                  v-for="(item, index) in basketSearch"
                  :key="index"
                  @click="getItem(item)"
                >
                  <span class="fsize14 primaryColor" :id="`${index}_search_alert_sheet_li_scrip_name`">{{
                    item.formattedInsName
                  }}</span>
                  <v-spacer></v-spacer>
                  <span class="fsize12 pr-2 primaryColor" :id="`${index}_search_alert_sheet_li_scrip_ex`">{{ item.exch }}</span>
                </li>
              </ul>
            </v-sheet>
          </div>
        </div>
      </div>

      <div class="my-4">
        <div class="row pa-0 ma-0 d-flex">
          <div class="col-5 ma-0 pa-0">
            <v-autocomplete
            id="valueOfis_select"
              v-model="valueOfis"
              :items="valueOfProperty"
              outlined
              dense
              height="42"
              single-line
              label="Select Account"
              class="funds fsize12"
              item-text="key"
              item-value="value"
              :error-messages="valueOferrorMessage"
            ></v-autocomplete>
          </div>
          <div class="col-7 ma-0 pa-0">
            <v-text-field
              type="text"
               id="ltpvalue_inp"
              label="Value"
              height="42px"
              v-model.trim="ltpValue"
              outlined
              hide-details
              :error-messages="ltpErrorMessage"
              :required="false"
              @keypress="keyPressNumeric($event)"
              dense
              class="px-6 fsize13"
            ></v-text-field>
          </div>
        </div>
      </div>

      <div class="d-flex justify-space-between">
        <div class="error-msg" id="alert_common_err_msg">
          <span v-if="commomErrorMessage" class="">{{commomErrorMessage}}</span>
        </div>
        <div>
          <v-btn id="alert_confirm_btn"
          depressed
          height="40"
          width="100"
          class="text-capitalize rounded white--text"
          :loading="alertNewLoader"
          :disabled="alertNewLoader"
          :color="$store.state.buttonThemeColor"
          @click="validate"
        >
          {{!alertFrom && editAlert ? 'Update' : 'Create'}}</v-btn
        >
        <v-btn id="alert_close_btn"
          depressed
          outlined
          height="40"
          width="100"
          color="#9b9b9b"
          class="text-capitalize secondaryColor rounded-sm-md cancelbtn ml-2"
          @click="alertDialog = false"
          >Cancel</v-btn
        >
        </div>
      </div>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import customIcon from "../components/customIcon.vue";
export default {
  data() {
    return {
      propertyItems: [ {key: "Last Traded Price" , value: 'LTP'}],
      property: "LTP",
      propertyErrorMessage: "",
      valueOfis: "greater",
      valueOfProperty: [
        {key : "Greater then (>)", value : 'greater'},
         {key : "Greater than or equal to (>=)", value : 'greaterequal'},
         {key : "Less than (<)", value : 'lesser'},
         {key : "Less than or equal to (<=)", value : 'lesserequal'},
         {key : "Equal to (==)", value : 'equal'}
      ],
      valueOferrorMessage: "",
      ltpValue: "",
      ltpErrorMessage: "",
      alertName: '',
      alerterrorMessage: '',
      commomErrorMessage: '',
      getValue: '',
      subscription: '',
      selectedToken:'',
      timerId: 0,
      alertSearch: '',
      isSelected: false,
      selectedItem : ''
    };
  },
  components: {
    customIcon,
  },
  computed: {
    ...mapState("order", ["basketSearch", 'editAlert', "alertNewLoader", "alertFrom"]),
    ...mapGetters("wsConnection", ["getOtherSubscription"]),
    alertDialog: {
      get() {
        if(this.$store.state.order.isalertDialog && this.editAlert){
          this.setUpdateValue(this.editAlert)
        }
        if(this.$store.state.order.isalertDialog){
          window.addEventListener('tickers-changed' , this.listenMwTicks)
        }
        return this.$store.state.order.isalertDialog;
      },
      set(val) {
        this.$store.commit("order/setIsalertDialog", val);
        if(!val){
          this.scbscribe('esc')
          this.resetField()
          window.removeEventListener('tickers-changed' , this.listenMwTicks)
        }
      },
    },
  },
  methods: {
    onClickOrderOutside() {
     this.alertSearch = "" 
      this.$store.commit("order/setBasketSearch", []);
    },
    keyPressNumeric(event) {
      if (
        event.keyCode != 13
      ) {
        var inp = String.fromCharCode(event.keyCode);
        if (/[0-9. ]/.test(inp)) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
      }
    },

    validate(){
      if(this.property && this.valueOfis && this.ltpValue && this.alertName){
        let json = {
        'alertName': this.alertName,
        'scripName': this.alertSearch,
        'alertType': this.property,
        'exch': this.selectedItem.exch,
        'operator': this.valueOfis,
        'token': this.selectedItem.token,
        'userId': this.$store.state.authentication.userId,
        'value': this.ltpValue,
        }
        !this.alertFrom && this.editAlert ? json.triggerId = this.editAlert.triggerId : ''
        !this.alertFrom && this.editAlert ? this.$store.dispatch('order/updateAlert', json) : this.$store.dispatch('order/newAlert', json)
      }else{
        this.alertName ? '' : this.alerterrorMessage = 'Name is required'
       if(this.alertName){
        this.ltpValue ? '' : this.commomErrorMessage = 'select an instrument'
       }
      }
    },
    getItem(item){
      this.selectedItem = item
      this.subscription = `${item.exch}|${item.token}`
      this.selectedToken = item.token
      this.$store.commit("order/setBasketSearch", []);
      this.isSelected = true
      this.alertSearch = item.exch == 'NSE' || item.exch == 'BSE' ? item.symbol : item.formattedInsName
      this.scbscribe('initial')
    },
    scbscribe(key){
      if(this.getOtherSubscription && key == 'esc' || key == 'initial'){
                let temp = {
                    sub : key == 'esc' ? this.getOtherSubscription : this.subscription,
                    statge: key
                }
                this.$store.dispatch('wsConnection/ortherSubscription', temp)
            }
    },
    listenMwTicks(event){
      var tickerData = event.detail.storage;
      if(tickerData && tickerData[this.selectedToken] && this.isSelected && tickerData[this.selectedToken].lp && !this.alertFrom){
        this.ltpValue = tickerData[this.selectedToken].lp
        this.isSelected = false
      }
    },
    resetField(){
     this.subscription = this.selectedToken = this.alertName = this.ltpValue = null
      this.valueOf = "greater"
      this.property = "LTP"
      this.alertSearch = null
      this.alerterrorMessage = this.commomErrorMessage =  null
    },
    setUpdateValue(val){
      this.isSelected = true
      if(!this.alertFrom){
        this.alertName = val.alertName
        this.valueOfis = val.operator
      }
      this.alertSearch = val.scripName
        this.ltpValue = !this.alertFrom ? val.value : val.ltp
    }
  },
  watch:{
    alertSearch: function (value){
      if(value && value.length > 2 && !this.isSelected){
          clearTimeout(this.timerId)
        var temp = {
          value: value.toString().toUpperCase(),
          where: "order",
        };
         this.timerId = setTimeout (()=>{
          this.$store.dispatch("marketWatch/getSearchScripts", temp)
         }, 500)
        }
    },
    editAlert: async function(val){
      this.selectedItem = val
       await this.resetField()
      if(val){
       await this.setUpdateValue(val)
      }
    }
  },
};
</script>

<style>
</style>